import http from "./httpService";
import {toast} from "react-toastify";
import { API_SERVER } from '../config/constant';
import {Notify} from "../components/Notifications/Notify";


const useHttps = API_SERVER.startsWith("https")


export const responseHandler = (
   response, // response object
   settrs  // array of state setters
) => {
    const ok = [200, 201];
    if (ok.includes(response.status)) {
        for (let index=0; index < settrs.length; index++) {
            settrs[index](response.data)
        }
    } else {
        console.log(response.statusText)
        // Notify("danger", response.statusText)
    }
}


export const fetchPage = async (endpoint) => {
    try {
        return await http.get(endpoint)
    } catch (error) {
        if (error?.response) {
            // Print the custom error message from the server
            console.log("Error",error?.response?.data);
            // toast.error('Creation failed: ' + JSON.stringify(error?.response?.data));
        } else {
            // Print a generic error message
            console.log(error);
            toast.error('Data Loading failed: ' + error?.message);
        }
        return {};
    }
}


const loopResults = async (data, _next) => {
    let response = await fetchPage(_next)
    let newData = []
    let nextPage = null
    try {
        newData = [
            ...data, ...response.data.results
        ]
        // data.push(response.data.results);
        if (response.data['next']) {
            nextPage = response.data['next']
            if (useHttps) {
                nextPage = nextPage.replace("http:", "https:")
            }
            // if (nextPage.startsWith('http://')) {
            //     nextPage = nextPage.replace('http://', 'https://');
            // }
        }
        // console.log(newData)
        // console.log(nextPage)
    } catch (error) {
        console.log(error)
    }

    return [newData, nextPage];
}

// GET
export const getData = async (endpoint, id) => {
    const url = `${endpoint}${id}/`;
    return await fetchPage(url);
}

export const getFilteredData = async (endpoint, query, attr,) => {
    let _next = `${endpoint}?${query}=${attr}`;
    let data = [];
    do {
        [data, _next] = await loopResults(data, _next);
        // console.log(data)
        // console.log(_next)
    }
    while (_next)
    return [data];
}

export const getSingleFilteredData = async (endpoint, query, attr,) => {
    let search = `${endpoint}?${query}=${attr}`;
    const data = await fetchPage(search);
    return data?.data;
}


export const getManyFilteredData = async (endpoint, filters) => {
    // Convert the filters object to a query string
    let queryFilters = []
    if (!Array.isArray(filters)) {
        for (const key in filters) {
            queryFilters.push(`${key}="${filters[key]}"`);
        }
    } else {
        queryFilters = filters
    }
    const queryString = queryFilters? Array.isArray(queryFilters)? endpoint+"?"+
          queryFilters
             .map((filter) => `${filter}`)
             .join('&')
          : `${endpoint}?${queryFilters}`
       :endpoint
    // console.log({filters,queryString},Array.isArray(filters))
    let _next = `${queryString}`;
    let data = [];
    do {
        [data, _next] = await loopResults(data, _next);
    }
    while (_next)
    return [data];
}


export const getManyData = async (endpoint) => {
    let _next = `${endpoint}`;
    let data = [];
    do {
        [data, _next] = await loopResults(data, _next);
    }
    while (_next);
    return [data];
}

export const getRawList = async (endpoint) => {
    return await fetchPage(endpoint)
}

export const getRawFilteredList = async (endpoint, filters) => {
    let queryFilters = []
    if (!Array.isArray(filters)) {
        for (const key in filters) {
            queryFilters.push(`${key}=${filters[key]}`);
        }
    } else {
        queryFilters = filters
    }
    const queryString = queryFilters? Array.isArray(queryFilters)? endpoint+"?"+
          queryFilters
             .map((filter) => `${filter}`)
             .join('&')
          : `${endpoint}?${queryFilters}`
       :endpoint
    // console.log({filters,queryString},Array.isArray(filters))
    let _next = `${queryString}`;
    let data = [];
    return await fetchPage(queryString)
}

export const getDropdownData = async (endpoint) => {
    const data = await fetchPage(endpoint);
    return data?.data;
}

// POST
export const postFormData = async (endpoint, formData, quietToast=false) => {
    let response;
    try {
        response = await http.post(
           endpoint,
           formData,
           { headers: { "Content-Type": "multipart/form-data" } }
        )
        if (!quietToast) {
            toast.success('Created successfully')
        }
    } catch (error) {
        if (error?.response) {
            // Print the custom error message from the server
            console.log("Error",error?.response?.data);
            // toast.error('Creation failed: ' + JSON.stringify(error?.response?.data?.message));
        } else {
            // Print a generic error message
            console.log(error);
            // toast.error('Creation failed: ' + error?.message);
        }
    }
    return response;
}

export const postData = async (endpoint, data, hideToast) => {
    try {
        let response = await http.post(endpoint, data, { headers: { "Content-Type": "application/json" } });
        !hideToast && toast.success('Created successfully')
        console.log(response)
        return response;
    } catch (error) {
        if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        !hideToast &&       Notify("danger", `${field}: ${message}`);
                    });
                } else {
                    // If the value is not an array, you can directly show the message
                    !hideToast &&       Notify("danger", `${field}: ${messages}`);
                }
            });
        } else {
            !hideToast &&    Notify("danger", error.response.request.responseText);
        }
        return error.response;
    }
}

// PUT
export const putFormData = async (endpoint, id, formData) => {
    let response;
    try {
        response = await http.put(
           `${endpoint}${id}/`,
           formData,
           { headers: { "Content-Type": "multipart/form-data" } }
        );
        toast.success('Updated Successfully')
    } catch (error) {
        if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        Notify("danger", `${field}: ${message}`);
                    });
                } else {
                    // If the value is not an array, you can directly show the message
                    Notify("danger", `${field}: ${messages}`);
                }
            });
        } else {
            Notify("danger", error.response.request.responseText);
        }

    }
    return response;
}

export const putData = async (endpoint, id, data, displayMessage) => {
    let response;
    try {
        // console.log(`${endpoint}${id}/`)
        // console.log(data)
        response = await http.put(
           `${endpoint}${id}/`,
           data,
           {headers: {"Content-Type": "application/json"}}
        );
        toast.success('Updated Successfully')
    } catch (error) {

        if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        Notify("danger", `${field}: ${message}`);
                    });
                } else {
                    // If the value is not an array, you can directly show the message
                    Notify("danger", `${field}: ${messages}`);
                }
            });
        } else {
            Notify("danger", error.response.request.responseText);
        }

    }
    return response;
}


// PATCH
export const patchData = async (endpoint, id, data, quiet=false) => {
    let response;
    try {
        console.log(`${endpoint}${id}/`)
        console.log(data)
        response = await http.patch(
           `${endpoint}${id}/`,
           data,
           { headers: { "Content-Type": "application/json" } }
        );
        if (!quiet) {
            toast.success('Updated Successfully')
        }
    } catch (error) {
        if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        Notify("danger", `${field}: ${message}`);
                    });
                } else {
                    // If the value is not an array, you can directly show the message
                    Notify("danger", `${field}: ${messages}`);
                }
            });
        } else {
            Notify("danger", error.response.request.responseText);
        }

    }
    return response;
}

// DELETE
export const deleteData = async (endpoint, id) => {
    let response;
    try {
        response = await http.delete(
           `${endpoint}${id}/`,
           { headers: { "Content-Type": "application/json" } }
        );
        toast.success('Deleted successfully')
    } catch (error) {
        if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        Notify("danger", `${field}: ${message}`);
                    });
                } else {
                    // If the value is not an array, you can directly show the message
                    Notify("danger", `${field}: ${messages}`);
                }
            });
        } else {
            Notify("danger", error.response.request.responseText);
        }

    }
    return response;
}

// OPTIONS
export const getOptions = async (endpoint) => {
    try {
        return await http.options(`${endpoint}`);
        // console.log({response})
    } catch (error) {
        if (error?.response) {
            // Print the custom error message from the server
            console.log("Error",error?.response?.data);
            toast.error('Options retrieval failed: ' + JSON.stringify(error?.response?.data?.message));
        } else {
            // Print a generic error message
            console.log(error);
            toast.error('Options retrieval failed: ' + error?.message);
        }
        return {};
    }
}