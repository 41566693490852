import React, { useState, useEffect } from 'react';
import {
   Button,
   Col,
   Form,
   Modal,
   OverlayTrigger,
   Row,
   Tooltip,
} from 'react-bootstrap';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import swal from 'sweetalert';

// Import Endpoints
import {
   AddressField,
   EditableField,
   EmailField,
   SwitchField,
   ReactGeneralSelect,
} from '../../components/Custom/editableField';

import mapOptions from '../../components/Custom/mapOptions';

import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/bootstrap.css'
import { Contacts } from '../../services';
import { useDispatch } from 'react-redux';
import httpService from '../../services/httpService';
import {
   updateContactList,
   updateFamilyLeadList,
   updateFamilyList,
   updateInvestorList,
} from '../../store/action_calls';


const CreateContactModal = props => {
   const [contactState, setContactState] = useState({
      language: '3'
   });
   const [contactOptionsState, setContactOptionsState] = useState({});
   const [disabled, setDisabled] = useState(false);
   const [isLarge, setIsLarge] = useState(false);
   // const [createAccount, setCreateAccount] = useState(false);

   const history = useHistory();

   const handleEdit = (e, name) => {
      console.log(e, name)
   }

   const handleSubmit = () => {
      console.log(contactState)
   }

   return (
      <React.Fragment>
         <Modal size="lg" show={props.showModal}>
            <Modal.Header closeButton>
               <Modal.Title as="h5">
                  Create Contact
               </Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Row>
                  <Col md={10}>
                     <Form>
                        <Form.Group as={Row}>
                           <Form.Label className={'mb-n5'} column sm={4}>
                              Name
                           </Form.Label>
                           <Col sm={4}>
                              <Form.Control
                                 readOnly={false}
                                 required
                                 name="first_name"
                                 placeholder='First Name'
                                 plaintext={false}
                                 value={
                                    contactState.first_name
                                       ? contactState['first_name']
                                       : ''
                                 }
                                 onChange={handleEdit}
                              />
                           </Col>
                           <Col sm={4}>
                              {/* -------------------- /api/contact/contact/ - beneficiary_id -------------------- */}
                              <Form.Control
                                 readOnly={false}
                                 required
                                 placeholder="Last Name"
                                 name="last_name"
                                 plaintext={false}
                                 value={
                                    contactState.last_name
                                       ? contactState['last_name']
                                       : ''
                                 }
                                 onChange={handleEdit}
                              />
                           </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="phoneNumber">
                           <Form.Label column sm={4}>
                              Phone Number
                           </Form.Label>
                           <Col sm={8}>
                              <PhoneInput
                                 inputStyle={{
                                    width: '100%',
                                    height: 43,
                                    fontSize: 13,
                                    borderRadius: 5,
                                    paddingLeft: 45,
                                 }}
                                 name="text_phone"
                                 field="text_phone"
                                 country={'us'}
                                 // value={subContact.phone ? subContact["phone"] : ''}
                                 placeholder="phone"
                                 inputProps={{
                                    name: 'text_phone',
                                    autoFocus: true,
                                 }}
                                 onChange={phone => {
                                    handleEdit({
                                       target: {
                                          name: 'text_phone',
                                          value: `+${phone}`,
                                       },
                                    });
                                 }}
                              />
                           </Col>
                           <Form.Control.Feedback type="invalid">
                              Please provide a valid phone.
                           </Form.Control.Feedback>
                        </Form.Group>
                        {/*this is a field that will become editable*/}
                        <EditableField
                           field={contactState.company_entity}
                           name={'company_entity'}
                           options={{ label: 'Company/Entity' }}
                           state={contactState}
                           setState={setContactState}
                           defaultSwitch={false}
                        />
                        <AddressField
                           field={contactState.address_id}
                           full_address={contactState.full_address}
                           state={contactState}
                           setState={setContactState}
                           nonEditMode={false}
                        />

                        {/*
                           Todo Add DOB Here
                        */}

                        <ReactGeneralSelect
                           // fieldwritten=""
                           defaultSwitch={false}
                           options={mapOptions(
                              contactOptionsState?.language?.choices || [],
                              'value',
                              'display_name'
                           )}
                           setState={setContactState}
                           state={contactState}
                           field="language"
                           name="language"
                           label="Language"
                           value={'English'}
                        />

                        <EmailField
                           field={contactState.primary_email}
                           name={'primary_email'}
                           options={{ label: 'Primary Email' }}
                           //    options={props.contactOptions.primary_email}
                           state={contactState}
                           setState={setContactState}
                           defaultSwitch={false}
                        />
                        <Row>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.family_bool}
                                 name={'family_bool'}
                                 options={{ label: 'Family' }}
                                 //    options={props.contactOptions.family_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.investor_lead_bool}
                                 name={'investor_lead_bool'}
                                 options={{ label: 'Investor Lead' }}
                                 //    options={props.contactOptions.investor_lead_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={
                                    props.isInvestor
                                       ? props.isInvestor
                                       : contactState.investor_bool
                                 }
                                 name={'investor_bool'}
                                 options={{ label: 'Investor' }}
                                 //    options={props.contactOptions.investor_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                        </Row>
                        <Row>
                           {/* todo booleans aren't allowing them to be edited*/}
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.family_lead_bool}
                                 name={'family_lead_bool'}
                                 options={{ label: 'Family Lead' }}
                                 //    options={props.contactOptions.family_lead_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.ambassador_bool}
                                 name={'ambassador_bool'}
                                 options={{ label: 'Ambassador' }}
                                 //    options={props.contactOptions.ambassador_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.property_contractor_bool}
                                 name={'property_contractor_bool'}
                                 options={{ label: 'Property Contractor' }}
                                 //    options={props.contactOptions.property_contractor_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                        </Row>
                        <Row>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.realtor_bool}
                                 name={'realtor_bool'}
                                 options={{ label: 'Realtor' }}
                                 //    options={props.contactOptions.property_source_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.company_entity_bool}
                                 name={'company_entity_bool'}
                                 options={{ label: 'Company/Entity' }}
                                 //    options={props.contactOptions.is_company_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                           <Col md={4}>
                              <SwitchField
                                 field={contactState.vendor_bool}
                                 name={'vendor_bool'}
                                 options={{ label: 'Vendor' }}
                                 //    options={props.contactOptions.vendor_bool}
                                 state={contactState}
                                 setState={setContactState}
                              />
                           </Col>
                        </Row>
                     </Form>
                  </Col>
               </Row>
            </Modal.Body>
            <Modal.Footer>
               <Row>
                  <Col className="float-right">
                     <div className="float-right" style={{ display: 'flex' }}>
                        {/*{*/}
                        {/*   props.createUserAccount &&*/}
                        {/*   <Col>*/}
                        {/*      <Row className="left">*/}
                        {/*         <Col>*/}
                        {/*            <Form.Label>Create User Account?</Form.Label>*/}
                        {/*         </Col>*/}
                        {/*         <Col>*/}
                        {/*            <Form.Check*/}
                        {/*               type="checkbox"*/}
                        {/*               value={createUserAccount}*/}
                        {/*               onChange={() => setCreateAccount(prevState => !prevState)}*/}
                        {/*            />*/}
                        {/*         </Col>*/}
                        {/*      </Row>*/}
                        {/*   </Col>*/}
                        {/*}*/}
                        <OverlayTrigger
                           overlay={<Tooltip>Create New Contact</Tooltip>}
                           style={{ float: 'right' }}
                        >
                           <Button
                              className="shadow-1 theme-bg border border-0"
                              disabled={disabled}
                           >
                              Cancel
                           </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                           overlay={<Tooltip>Create New Contact</Tooltip>}
                           style={{ float: 'right' }}
                        >
                           <Button
                              className="shadow-1 theme-bg border border-0"
                              disabled={disabled}
                              onClick={handleSubmit}
                           >
                              Save
                              {disabled && (
                                 <span
                                    className="spinner-border spinner-border-sm ml-1"
                                    role="status"
                                    aria-hidden="true"
                                 />
                              )}
                           </Button>
                        </OverlayTrigger>
                     </div>
                  </Col>
               </Row>
            </Modal.Footer>
         </Modal>
      </React.Fragment>
   );
};

const CreateContact = (props) => {
   const [showModal, setShowModal] = useState(false);
   const [hovered, setHovered] = useState(false);

   const CreateButton = (navbar, name) => {
      return navbar ? (
         // <div >
         <li
            onClick={() => setShowModal(true)}
            className="nav-link"
            onMouseOver={() => setHovered(true)}
            onMouseOut={() => setHovered(false)}
            style={{
               color: hovered ? '#000000' : '#fff',
               cursor: hovered ? 'pointer' : '',
               backgroundColor: hovered ? '#F0CEA6':'',
               marginLeft: -122,
               padding:0,
               paddingLeft: 60,
               // marginTop: 6.2,
               listStyleType: hovered ? 'disc' : '',
            }}
         >
            <a
               // class="nav-link"
               href="#"
            >
               Create {name}
            </a>
         </li>
         // </div>
      ) : (
         <OverlayTrigger
            overlay={<Tooltip>Create {name}</Tooltip>}
            style={{ float: 'right' }}
         >
            <Button
               className={
                  props.isNavbar
                     ? 'transparent'
                     : 'shadow-1 theme-bg border border-0'
               }
               size="md"
               onClick={() => setShowModal(true)}
            >
               + Create {name}
            </Button>
         </OverlayTrigger>
      );
   };

   return (
      <>
         {
            props.isSituation
               ? <Button
                  className=" bg-transparent m-1 border-0"
                  size="sm"
                  onClick={() => setShowModal(true)}
                  style={{ float: 'right' }}
               >
                  <i className="feather icon-plus fa-2x text-dark mx-n3" />
               </Button>
               : CreateButton(props.isNavbar, props.name)
         }
         {
            <CreateContactModal
               show
               {...props}
            />
         }
      </>
   )

}

export default (CreateContact);
